<template>
  <section id="knowledge-base">
    <!-- seach banner  -->
    <v-card
      flat
      class="knowledge-base-bg d-flex align-center justify-center text-center mb-7"
    >
      <v-card-text>
        <p class="kb-title text-2xl font-weight-semibold primary--text mb-2">
          Hello, how can we help?
        </p>
        <p class="mb-7">
          or choose a category to quickly find the help you need
        </p>

        <v-form class="kb-search-input mx-auto">
          <v-text-field
            v-model="knowledgeBaseSearchQuery"
            outlined
            placeholder="Ask a question...."
            hide-details
            class="kb-search-input"
          >
            <template #prepend-inner>
              <v-icon
                size="23"
                class="mx-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- kb search content -->
    <div id="knowledge-base-content">
      <v-row class="kb-search-content-info match-height">
        <v-col
          v-for="item in filteredKB"
          :key="item.character"
          md="4"
          sm="6"
          cols="12"
          class="kb-search-content"
        >
          <v-card>
            <!-- title -->
            <v-card-title class="kbc-title d-flex flex-nowrap">
              <v-icon
                :color="item.color ? item.color:'' "
                :class="`me-3 ${item.class ? item.class :''}`"
              >
                {{ item.icon }}
              </v-icon>

              <span class="text-truncate">{{ item.title }} ({{ item.questions.length }})</span>
            </v-card-title>

            <div class="kb-questions py-1">
              <div
                v-for="que in item.questions"
                :key="que.question"
                class="kb-question d-flex align-start text-sm px-5 my-6 cursor-pointer"
                @click="$router.push({ name: 'page-knowledge-base-question', params: { category: $route.params.category, slug: que.slug } })"
              >
                <v-icon
                  size="14"
                  class="me-2 mt-1"
                  color="secondary"
                >
                  {{ icons.mdiCheckboxBlankCircleOutline }}
                </v-icon>

                <span class="text--secondary">{{ que.question }}</span>
              </div>
            </div>
          </v-card>
        </v-col>

        <!-- no result found -->
        <v-col
          v-show="!filteredKB.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            Search result not found!!
          </h4>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
import { mdiAccount, mdiCheckboxBlankCircleOutline, mdiCogOutline, mdiMagnify } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  setup() {
    const knowledgeBaseSearchQuery = ref('')

    const kbContentData = [
      {
        category: 'account-settings',
        icon: mdiCogOutline,
        color: 'primary',
        title: 'Account Settings',
        questions: [
          { question: 'How Secure Is My Password?', slug: 'secure-password-doc' },
          { question: 'Can I Change My Username?', slug: 'change-username-doc' },
          { question: 'Where Can I Upload My Avatar?', slug: 'change-avatar-doc' },
          { question: 'How Do I Change My Timezone?', slug: 'change-timezone-doc' },
          { question: 'How Do I Change My Password?', slug: 'change-password-doc' },
        ],
      },
      {
        category: 'patient-chart',
        icon: mdiAccount,
        color: 'warning',
        title: 'Patient Chart',
        questions: [
          { question: 'How can I add a new Patient?', slug: 'add-patient-doc' },
          { question: 'Where do i find the Detox Forms and Results?', slug: 'detox-doc' },
          { question: 'How can I add a new Task?', slug: 'add-task-doc' },
          { question: 'Where do I find the Intake Review?', slug: 'intake-review-doc' },
        ],
      },
    ]

    const filteredKB = computed(() => {
      const knowledgeBaseSearchQueryLower = knowledgeBaseSearchQuery.value.toLowerCase()

      return kbContentData.filter(
        item =>
          // eslint-disable-next-line operator-linebreak
          item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) ||
          item.questions.filter(queObj => queObj.question.toLowerCase().includes(knowledgeBaseSearchQueryLower)).length,
      )
    })

    return {
      knowledgeBaseSearchQuery,
      kbContentData,
      filteredKB,
      icons: { mdiMagnify, mdiCheckboxBlankCircleOutline },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/knowledge-base.scss';
</style>
